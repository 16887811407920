exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-baby-registry-tsx": () => import("./../../../src/templates/BabyRegistry.tsx" /* webpackChunkName: "component---src-templates-baby-registry-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/Category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/Index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-papas-choice-tsx": () => import("./../../../src/templates/PapasChoice.tsx" /* webpackChunkName: "component---src-templates-papas-choice-tsx" */),
  "component---src-templates-pickup-products-tsx": () => import("./../../../src/templates/PickupProducts.tsx" /* webpackChunkName: "component---src-templates-pickup-products-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

